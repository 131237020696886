
import {
    reactive, PropType, watch, computed, ref, defineComponent, nextTick
} from 'vue';
import { checkRelayName } from '@/methods/rules/device';
import { form } from '@/components/view/pm/set-resident';
import { RelayItem } from '../types/relay.d';
import { showTalkingLimitTip } from '../utils/device-relay';

export default defineComponent({
    props: {
        relay: {
            type: Array as PropType<RelayItem[]|[]>,
            default: () => ([])
        },
        relayInitFormat: {
            type: Object as PropType<RelayItem|Record<string, any>>,
            default: () => ({
                name: { value: '', show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: { value: '', show: true, disabled: false },
                unlock: {
                    show: true, showHome: 1, showTalking: 1, pin: 1, rf: 1, face: 1, ble: 1, nfc: 1
                },
                schedule: { show: false, enable: 0, access: [] }
            })
        },
        securityRelay: {
            type: Array as PropType<RelayItem[]|[]>,
            default: () => ([])
        },
        securityRelayInitFormat: {
            type: Object as PropType<RelayItem|Record<string, any>>,
            default: () => ({
                name: { value: '', show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: { value: '', show: true, disabled: false },
                unlock: {
                    show: true, showHome: 1, showTalking: 1, pin: 1, rf: 1, face: 1, ble: 1, nfc: 1
                },
                schedule: { show: false, enable: 0, access: [] }
            })
        },
        limitOneEnable: {
            type: Boolean,
            default: true
        },
        showRelayAddBtn: {
            type: Boolean,
            default: true
        },
        showSecurityRelayAddBtn: {
            type: Boolean,
            default: true
        },
        dtmfCode: {
            type: Array as PropType<{ label: string; value: string }[]>,
            default: () => ([
                { label: '0', value: '0' }, { label: '1', value: '1' }, { label: '2', value: '2' },
                { label: '3', value: '3' }, { label: '4', value: '4' }, { label: '5', value: '5' },
                { label: '6', value: '6' }, { label: '7', value: '7' }, { label: '8', value: '8' },
                { label: '9', value: '9' }, { label: '#', value: '#' }, { label: '*', value: '*' }
            ])
        },
        unlockMethods: {
            type: Array as PropType<{label: string; value: string}[]>,
            default: () => ([
                { label: WordList.SmartPlusHomepage, value: 'showHome' },
                { label: WordList.SmartPlusTalkingpage, value: 'showTalking' },
                { label: WordList.ProperAllTextPIN, value: 'pin' },
                { label: WordList.ProperAllTextPhotos, value: 'face' },
                { label: WordList.DeliveryRfCard, value: 'rf' },
                { label: WordList.Bluetooth, value: 'ble' },
                { label: WordList.NFC, value: 'nfc' }
            ])
        },
        relaySchedule: {
            type: Array,
            default: () => ([])
        },
        accessGroupForRelaySchedule: {
            type: Array as PropType<[{Name: string;ID: string}][]>,
            default: () => ([])
        }
    },
    setup(props, { emit }) {
        const dtmfCodeList = props.dtmfCode;
        const unlockMethodList = props.unlockMethods;
        const relayScheduleList = props.relaySchedule;
        const formData = reactive<{relay: RelayItem[]; securityRelay: RelayItem[]}>({
            relay: [],
            securityRelay: []
        });
        const accessGroupListForRelayScheduleList = ref<{Name: string;ID: string}[][]>([[], [], [], []]);
        const rules: Record<string, any> = {};
        for (let index = 0; index < 4; index++) {
            rules[`relay[${index}].name.value`] = [
                { required: true, message: WordList.RuleRelayEmpty, trigger: 'blur' },
                { validator: checkRelayName }
            ];
            rules[`relay[${index}].schedule.access`] = [
                {
                    trigger: 'change',
                    validator: (rule: any, val: any, callBack: any) => {
                        if (val.length > 10) {
                            return callBack(new Error(WordList.MaximumOf10AccessGroups));
                        } if (val.length === 0) {
                            return callBack(new Error(WordList.SelectLeastOneSchedule));
                        }
                        return callBack();
                    }
                }
            ];
            rules[`relay[${index}].dtmf.showSmartPlusTalkingLimitTip`] = [
                {
                    validator: (rule: any, val: any, callBack: any) => {
                        if (val) {
                            return callBack(new Error(WordList.SmartPlusTalkingRelayLimitTip));
                        }
                        return callBack();
                    }
                }
            ];
            if (index < 2) {
                rules[`securityRelay[${index}].name.value`] = [
                    { required: true, message: WordList.RuleRelayEmpty, trigger: 'blur' },
                    { validator: checkRelayName }
                ];
                rules[`securityRelay[${index}].dtmf.showSmartPlusTalkingLimitTip`] = [
                    {
                        validator: (rule: any, val: any, callBack: any) => {
                            if (val) {
                                return callBack(new Error(WordList.SmartPlusTalkingRelayLimitTip));
                            }
                            return callBack();
                        }
                    }
                ];
            }
        }
        // 各种设备要初始化的数据，要变动的数据
        watch([() => props.relay, () => props.securityRelay], ([newRelay, newSecurityRelay]) => {
            formData.relay = newRelay as RelayItem[];
            formData.securityRelay = newSecurityRelay as RelayItem[];
            limitOneEnableFn();
        }, { immediate: true });
        // accessGroupListForRelaySchedule赋值
        watch(() => props.accessGroupForRelaySchedule, (newVal) => {
            const newValList = newVal.map((item) => item.sort((a, b) => a.Name.localeCompare(b.Name)));
            newValList.forEach((item, index) => {
                accessGroupListForRelayScheduleList.value[index] = item;
            });
        }, { deep: true });
        // 是否显示relay添加按钮
        const relayAddBtnShow = computed(() => {
            // 外部控制优先
            if (!props.showRelayAddBtn) {
                return false;
            }
            // 内部数据变化控制
            if (formData.relay.length < 4) {
                return true;
            }
            return false;
        });
        // 是否显示 securityRelay 添加按钮
        const securityRelayAddBtnShow = computed(() => {
            // 外部控制优先
            if (!props.showSecurityRelayAddBtn) {
                return false;
            }
            // 内部数据变化控制
            if (formData.securityRelay.length < 2) {
                return true;
            }
            return false;
        });
        /**
         * 未勾选SmartPlus Talking Page的relay和勾选的relay不能设置一样的DTMF Code，若一样，则显示提示语
         */
        function showTalkingLimitTipFn(data: RelayItem[]) {
            showTalkingLimitTip(data);
            nextTick(() => {
                formRef.value.validate();
            });
        }
        function changeRelayEnable($event: number, type: 'relay'|'securityRelay') {
            showTalkingLimitTipFn([...formData.relay, ...formData.securityRelay]);
            limitOneEnableFn();
        }
        function changeUnlockCheckbox($event: number, type: 'relay'|'securityRelay') {
            showTalkingLimitTipFn([...formData.relay, ...formData.securityRelay]);
        }
        function changeDtmf($event: number, type: 'relay'|'securityRelay') {
            showTalkingLimitTipFn([...formData.relay, ...formData.securityRelay]);
        }

        const dtmfCodeMap = {
            0: '#',
            1: '0',
            2: '1',
            3: '2'
        };
        function addRealy() {
            const data = JSON.parse(JSON.stringify(props.relayInitFormat));
            data.name.value = `${WordList.ProperAllTextRelay}${formData.relay.length + 1}`;
            // @ts-ignore
            data.dtmf.value = dtmfCodeMap[formData.relay.length];
            formData.relay.push(data);
            emit('addRealyCallBack', formData);
            limitOneEnableFn();
            showTalkingLimitTipFn([...formData.relay, ...formData.securityRelay]);
        }
        function addSecurityRealy() {
            const data = JSON.parse(JSON.stringify(props.securityRelayInitFormat));
            data.name.value = `${WordList.SecurityRelay}${formData.securityRelay.length + 1}`;
            // @ts-ignore
            data.dtmf.value = dtmfCodeMap[formData.securityRelay.length];
            formData.securityRelay.push(data);
            emit('addSecurityRealyCallBack', formData);
            limitOneEnableFn();
            showTalkingLimitTipFn([...formData.relay, ...formData.securityRelay]);
        }
        // 至少一个relay要开启：
        function limitOneEnableFn() {
            if (props.limitOneEnable) {
                console.log('111');
                //  relay disable属性控制代码
                // 把最后一个relay的disabled属性设置为true
                const filterEnableRelay = formData.relay.filter((item) => item.enable.value === 1);
                const filterEnableSecurityRelay = formData.securityRelay.filter((item) => item.enable.value === 1);
                if ((filterEnableRelay.length + filterEnableSecurityRelay.length) === 1) {
                    if (filterEnableRelay.length === 1) {
                        const index = formData.relay.findIndex((item) => item.enable.value === 1);
                        formData.relay[index].enable.disabled = true;
                    }
                    if (filterEnableSecurityRelay.length === 1) {
                        const index = formData.securityRelay.findIndex((item) => item.enable.value === 1);
                        formData.securityRelay[index].enable.disabled = true;
                    }
                } else {
                    // 把relay的disabled属性设置为false
                    formData.relay.forEach((item, index) => {
                        formData.relay[index].enable.disabled = false;
                    });
                    formData.securityRelay.forEach((item, index) => {
                        formData.securityRelay[index].enable.disabled = false;
                    });
                }
            }
        }
        const formRef = ref();
        setTimeout(() => {
            // 初始化校验表单历史数据时候符合rule
            formRef.value.validate();
        }, 1000);
        async function validate(callbackFn: (relay: RelayItem[]|[], securityRelay: RelayItem[]|[]) => void) {
            // eslint-disable-next-line consistent-return
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    callbackFn(formData.relay, formData.securityRelay);
                } else {
                    return false;
                }
            });
        }
        return {
            formData,
            validate,
            formRef,
            rules,
            dtmfCodeList,
            unlockMethodList,
            relayScheduleList,
            accessGroupListForRelayScheduleList,
            limitOneEnableFn,
            relayAddBtnShow,
            securityRelayAddBtnShow,
            addRealy,
            addSecurityRealy,
            changeUnlockCheckbox,
            changeDtmf,
            changeRelayEnable
        };
    }
});

